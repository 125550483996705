import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import usePrivacyLink from '../../../../hooks/usePrivacyLink';
import { LOCALE_PATHS } from '../../../../utils/locale';
import { TrustpilotBusinessReviewHorizontal } from '../../../atoms/trustpilot-business-review';
import { BusinessReviewHorizontal } from '../../../atoms/business-reviews';
import footerImage from '../../../../assets/ppc-awards-footer.png';

const Footer = ({ locale, rating }) => {
	const footerData = useStaticQuery(graphql`
		query footerData {
			allDatoCmsGluFooter {
				nodes {
					badgesRowOne {
						alt
						url
						gatsbyImageData(height: 80, layout: CONSTRAINED)
					}
					badgesRowTwo {
						alt
						url
						gatsbyImageData(height: 80, layout: CONSTRAINED)
					}
					id
					locale
					showTrustpilot
					title
				}
			}
			datoCmsSiteSpecificSetting(locale: { eq: "en-CA" }) {
				googleReview {
					alt
					url
				}
			}
		}
	`);

	const footerDataForLocale = footerData.allDatoCmsGluFooter.nodes.filter(
		(node) => node.locale === locale
	);

	const { badgesRowOne, badgesRowTwo, showTrustpilot, title } =
		locale !== 'en-AU' && footerDataForLocale[0];
	const { privacyLink } = usePrivacyLink(LOCALE_PATHS[locale]);

	return (
		<footer className="font-lexend-light">
			{LOCALE_PATHS[locale] === 'ie' || locale === 'en-CA' ? null : (
				<div className="p-5 text-center bg-brand-pale-400">
					<div>
						<h3 className="mb-5 text-2xl font-bold">{title}</h3>
					</div>
					<div className="flex flex-col items-center justify-center">
						<div className="flex items-center gap-10">
							{locale !== 'en-AU' &&
								badgesRowOne.map((badge) => (
									<GatsbyImage
										image={badge.gatsbyImageData}
										alt={badge.alt || ''}
										key={badge.id}
									/>
								))}
						</div>
					</div>
				</div>
			)}

			{locale !== 'en-AU' ||
				(locale !== 'en-CA' && showTrustpilot && (
					<div className="flex flex-wrap items-center justify-center py-8 mx-auto font-bold max-w-m-screen max-auto">
						<TrustpilotBusinessReviewHorizontal
							className="justify-center mb-10 text-center"
							rating={rating}
						/>
					</div>
				))}

			{locale === 'en-CA' && (
				<div className="flex flex-wrap items-center justify-center py-8 mx-auto font-bold max-w-m-screen max-auto">
					<BusinessReviewHorizontal
						className="justify-center mb-10 text-center"
						googleReview={
							footerData.datoCmsSiteSpecificSetting.googleReview
						}
						onlyGR
					/>
				</div>
			)}

			<div className="flex flex-col items-center justify-center p-5 text-sm text-white md:p-10 bg-brand-blue-400">
				<div className="flex items-center gap-4 mb-5">
					{locale !== 'en-AU' &&
						locale !== 'en-CA' &&
						Array.isArray(badgesRowTwo) &&
						badgesRowTwo.map((badge) => (
							<GatsbyImage
								image={badge.gatsbyImageData}
								alt={badge.alt || ''}
								key={badge.id}
							/>
						))}

					{locale === 'en-CA' ? (
						<>
							<img
								src={footerImage}
								alt="Canadian Awards"
								className="max-h-[123px] mx-auto lg:ml-auto"
							/>
						</>
					) : null}
				</div>
				<a
					href={privacyLink}
					target="_blank"
					rel="noreferrer"
					className="text-blue-300 underline"
				>
					Privacy policy
				</a>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	locale: 'en',
	rating: '',
};

Footer.propTypes = {
	locale: PropTypes.string,
	rating: PropTypes.string,
};

export { Footer };
